import React, { useEffect } from 'react';

import SEO from '../components/seo';
import { PageTitle } from '../components/styles/global';
import conferenceTerms from '../downloads/ppp-regulamin-konferencji-2021.pdf';
import DownloadIcon from '../images/download.inline.svg';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const ConferencePage = () => {
  return (
    <>
      <SEO
        title={'4th Professional Piercers Poland Conference 2024'}
        description={
          'Czwarta edycja konferencji dotyczącej piercingu zorganizowana przez Professional Piercers Poland'
        }
      />
      <PageTitle>4th Professional Piercers Poland Conference 2024</PageTitle>
      <p>
        Po więcej szczegółów zajrzyjcie na <a class="link" href="https://pppconference.conrego.app/" target="_blank">https://pppconference.conrego.app/</a>
      </p>
    </>
  );
};

export default ConferencePage;


